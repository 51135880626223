import './App.css';
import Mobile from "./screens/Mobile";
import DesktopPTBR from "./screens/DesktopPTBR";
import DesktopEN from "./screens/DesktopEN";
import {isMobile} from 'react-device-detect';
import {useEffect, useState} from "react";

import TagManager from 'react-gtm-module'


const tagManagerArgs = {
    gtmId: 'G-WNGFZMPDE7'
}

TagManager.initialize(tagManagerArgs)


const App = (props) => {


    const [isPortuguese, setIsPortuguese] = useState(true)

    useEffect(() => {
        if (window.location.host === 'promosquito.com') {
            document.title = 'Baygon promosquito';
            setIsPortuguese(true)
        } else {
            document.title = 'Baygon bloodysale';
            setIsPortuguese(false)
        }
    }, []);

    return (
        <div className="App">
            <header className="App-header">
                <DesktopPTBR/>
            </header>
        </div>
    );
}

export default App;
