import {Alignment, EventType, Fit, Layout, useRive, useStateMachineInput} from "@rive-app/react-canvas";
import {useEffect, useRef, useState} from "react";
import Webcam from "react-webcam";
import axios from 'axios'
import useWindowDimensions from "../hooks/useWindowDimensions";
import Loading from "./Loading";
import rivePTBR from '../assets/animations/baygon-ptbr.riv'
import riveEN from '../assets/animations/baygon-en.riv'
import TagManager from "react-gtm-module";

const Mobile = (props) => {

    const {rive: backgroundRive, RiveComponent: BackgroundRiveComponent} = useRive({
        src: window.location.host === 'promosquito.com' ? rivePTBR : riveEN,
        stateMachines: "TELA BASES",
        autoplay: false,
        artboard: "TELA FULL",
        layout: new Layout({
            fit: Fit.Cover,
            alignment: Alignment.Center
        })
    });

    const {height, width} = useWindowDimensions()

    const [showCamera, setShowCamera] = useState(false)
    const [image, setImage] = useState()
    const [result, setResult] = useState()
    const [coppied, setCoppied] = useState(false)
    const [isLoading, setIsLoading] = useState(true)


    const cuppon = useRef('');

    const camera = useRef()


    const openApp = useStateMachineInput(backgroundRive, "TELA BASES", "OPEN APP")
    const resultReady = useStateMachineInput(backgroundRive, "TELA BASES", "RESULT READY")
    const resultWin = useStateMachineInput(backgroundRive, "TELA BASES", "RESULT WIN")
    const resultLose = useStateMachineInput(backgroundRive, "TELA BASES", "RESULT LOSE")
    const copyPressed = useStateMachineInput(backgroundRive, "TELA BASES", "COD COPY PRESSED")

    useEffect(() => {
        if (openApp && resultWin && resultLose && resultReady) {
            openApp.fire()
        }
    }, [openApp, resultWin, resultLose, resultReady, copyPressed])

    useEffect(() => {
        if (backgroundRive) {
            backgroundRive.on(EventType.RiveEvent, eventReceived);
            backgroundRive.on('load', setIsLoading(false))
            setTimeout(() => {
                backgroundRive.play()
            }, 700)
        }
    }, [backgroundRive]);

    useEffect(() => {
        if (result === undefined) return;
        resultWin.value = result;
        resultLose.value = !result;
        resultReady.fire()
    }, [result])

    useEffect(() => {
        if (coppied) {
            copyPressed.value = true;
            setTimeout(() => {
                copyPressed.value = false;
                setCoppied(false)
            }, 4000)
        }
    }, [coppied])

    useEffect(() => {
        backgroundRive && backgroundRive.setTextRunValue("RUN_CODIGO", cuppon.current);
    }, [cuppon.current])

    const eventReceived = (event) => {
        switch (event.data.name) {
            case 'EVENT_GOTIT': {
                setShowCamera(true)
                TagManager.dataLayer({
                    event: "GOT_IT_PRESSED"
                })
                break;
            }
            case 'EVENT_SCAN': {
                makeScan()
                TagManager.dataLayer({
                    event: "SCAN_PRESSED"
                })
                break;
            }
            case 'EVENT_COPY': {
                TagManager.dataLayer({
                    event: "COPY_CUPPON_PRESSED"
                })
                setTimeout(() => {
                    //          navigator.clipboard.writeText(cuppon.current)
                }, 0)
                setTimeout(() =>
                        setCoppied(true)
                    , 3000)
                break;
            }
            case 'EVENT_RESTART': {
                TagManager.dataLayer({
                    event: "RESTART_PRESSED"
                })
                window.location.reload();
                break;
            }
            case 'EVENT_GOTOSITE': {
                TagManager.dataLayer({
                    event: "GOTOSITE_PRESSED"
                })
                window.location.href = 'https://www.amazon.com.br/stores/Baygon/page/F4914045-B80F-4D21-879C-509059A8838F?ref_=ast_bln';
                break;
            }
            default:
            //do nothing


        }
    }

    const makeScan = () => {
        const photo = camera.current.getScreenshot();
        setImage(photo)
        setShowCamera(false)
        getResult(photo)
    }


    const getResult = (image) => {
        axios.post('https://0bvlr3mijh.execute-api.us-east-1.amazonaws.com/api/upload', {
            image_content: image
        })
            .then(({data}) => {
                setResult(data.result)
                if (data.result) {
                    TagManager.dataLayer({
                        event: "SUCCESS_RESULT"
                    })
                    cuppon.current = data.cuppon
                }
                else{
                    TagManager.dataLayer({
                        event: "FAILED_RESULT"
                    })
                }
            })
            .catch(err => {
                if (err.response) console.error(err.response)
                else console.error(err)
                setResult(false)
            })
    }

    return (
        <>
            {
                showCamera && <Webcam
                    className='absolute'
                    audio={false}
                    height={height}
                    ref={camera}
                    screenshotFormat="image/jpeg"
                    width={width}
                    videoConstraints={{
                        width: height,
                        height: width,
                        facingMode: 'environment'
                    }}
                />
            }
            {
                image && <div className='absolute' style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover'
                }}/>
            }
            <BackgroundRiveComponent className={'absolute'}/>
            {
                cuppon.current && cuppon.current.length > 0 &&
                <div className="absolute" style={{height: '10%', top: '75%'}} onClick={() => {
                    navigator.clipboard.writeText(cuppon.current)
                    setCoppied(true)
                }}/>
            }
            <Loading isLoading={isLoading}/>
        </>
    );
}

export default Mobile;
