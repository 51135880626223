import React from 'react';
import Rive, {Fit, Layout} from "@rive-app/react-canvas";

const App = () => {
    return <div className='absolute flex'
                style={{backgroundColor: 'white'}}>
        <div className="flex" style={{justifyContent: "center"}}>
            <Rive
                src={require("../assets/animations/logo-baygon-promosquito.riv")}
                artboard="LOGO"
                stateMachines="TELA BASES"
                autoPlay={true}
                loop={true}
                layout={new Layout({ fit: Fit.Contain})}
                style={{
                    width: '100%',
                }}/>
        </div>
        <div style={{
            width: '100%',
            minHeight: 40,
            maxHeight: 40,
            margin: '40px 0',
            backgroundRepeat: 'repeat-x',
            backgroundSize: 'contain',
            backgroundImage: `url(${require('../assets/images/end.png')}`
        }}>

        </div>
        <div
            style={{
                width: '100%',
                height: '20%',
                minHeight: 150,
                backgroundImage: `url(${require('../assets/images/bottom.png')}`,
                backgroundSize: 'cover',
                backgroundPosition: 'center top',
                backgroundRepeat: 'repeat-x',
                aspectRatio: 24 / 5,
                display: 'flex',
                alignItems: 'center'
            }}>
            <div className="flex" style={{height: '80%'}}>
                <img src={require('../assets/images/scjohnson.png')} style={{height: '15%', maxHeight: 100}}
                     alt="Logo SC Johnson"/>
            </div>
        </div>
    </div>
}

export default App
